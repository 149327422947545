import shallow from 'zustand/shallow';
import React, { useState, useEffect, createRef } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dagLogoInlinePath from './../../../assets/dag-logo-inline.png';
import './navbar.scss';
import { FANTOM_CHAIN_PARAMS } from '../../../constants';
import { useWeb3Accounts } from '../../../hooks/ethers/account';
import { useContract } from '../../../hooks/ethers/contracts';
import { useStore } from '../../../hooks/useStore';
import { useWeb3Modal } from '../../../hooks/useWeb3Modal';
import Loader from '../loader/loader';
import RoundButton from '../button/round-button';
import toast from 'react-hot-toast';
import { handleShortenAddress } from '../../../utils/formater';

export default function Navbar() {

  const web3ModalConfig = {
    autoLoad: true,
    network: '',
  };
  const { _walletExist, provider, loadWeb3Modal, logoutWeb3Modal } = useWeb3Modal(web3ModalConfig);

  const accounts = useWeb3Accounts();
  const setProvider = useStore(state => state.setProvider, shallow);
  const [isConnectingWallet, setIsConnectingWallet] = useState(false);

  useEffect(() => {
    if (provider) {
      setProvider({ provider });
    }
  }, [provider, setProvider]);

  const handleAddOrChangeNetwork = async () => {
    setIsConnectingWallet(true)
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: FANTOM_CHAIN_PARAMS.chainId }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [FANTOM_CHAIN_PARAMS],
          });
        } catch (addError) {
          throw new Error(addError);
        }
      }
    } finally {
      setIsConnectingWallet(false);
    }
  };

  const handleConnectWallet = async () => {

    const CustomToastWithLink = () => (
      <div>
        No Wallet found. <a href="https://metamask.io/download/">Please install MetaMask!</a>
      </div>
    );

    if (
      typeof window.ethereum === 'undefined' ||
      typeof window.web3 === 'undefined'
    ) {
      return toast(CustomToastWithLink, {
        autoClose: false,
        closeOnClick: true,
      });
    }

    setIsConnectingWallet(true);
    if (provider === undefined) {
      try {
        await loadWeb3Modal();
        await handleAddOrChangeNetwork();
      } catch (err) {
        toast(err.message)
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsConnectingWallet(false);
      }
    }
  };

  const handleDisconnectWallet = async e => {
    setIsConnectingWallet(true);
    e.preventDefault();
    await logoutWeb3Modal();
    setIsConnectingWallet(false);
  };

  return (
    <header>
      <div className="notification-bar">
        <p>We are at capacity, no more deposits are accepted. No yield is generated until DNF can join the <a href="https://medium.com/constellationlabs/results-are-in-integrationnet-node-operator-lottery-winners-announced-e1cd5103724f">IntegrationNet</a>.</p>
      </div>
      <div className="navbar">
        <Container>
          <div className="navbar__content">
            <img src={dagLogoInlinePath} alt="dag node finance logo" />
            <ul>
              <li>
                <Link to="/">Invest</Link>
              </li>
              <li>
                <Link to="/node-pool">Your NFTs</Link>
              </li>
              <li>
                {!provider ? (
                  <RoundButton onClick={handleConnectWallet} variant="primary">
                    <div className="connect-button-container" >
                      {isConnectingWallet && <Loader />}
                      <span style={{ marginLeft: isConnectingWallet ? 12 : 0 }}>Connect</span>
                    </div>
                  </RoundButton>
                ) : (
                  <RoundButton onClick={handleDisconnectWallet} variant="primary">
                    <div className="connect-button-container" >
                      {isConnectingWallet && <Loader />}
                      <span style={{ marginLeft: 0 }}>Disconnect {accounts && handleShortenAddress(accounts[0])}</span>
                    </div>
                  </RoundButton>
                )}
              </li>
            </ul>
          </div>
        </Container>
      </div >
    </header >
  );
}
